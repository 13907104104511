export const useFormStore = (id) =>
  defineStore("formStore/" + id, {
    state: () => {
      return {
        forms: [],
      };
    },
    actions: {
      addForm(form) {
        const formIndex = this.forms.findIndex(({ id }) => form.id === id);

        if (formIndex === -1) {
          this.forms.push(form);
        } else {
          this.forms[formIndex] = form;
        }
      },
      removeForm(id) {
        this.forms = this.forms.filter((form) => form.id !== id);
      },
      resetForms() {
        this.forms = [];
      },
      scrollToFormWithError() {
        let topmostErrorFormOffset = null;

        let topmostErrorForm = this.forms.reduce((tomostErrorForm, form) => {
          const formOffsetTop = document.getElementById(form.id)?.offsetTop;

          if (
            undefined !== formOffsetTop &&
            this.isFormHasAnyError(form) &&
            (null === topmostErrorFormOffset ||
              formOffsetTop < topmostErrorFormOffset)
          ) {
            topmostErrorFormOffset = formOffsetTop;

            return form;
          }

          return tomostErrorForm;
        }, null);

        if (null !== topmostErrorFormOffset) {
          location.hash = "";
          location.hash = "#" + topmostErrorForm.id;
        }
      },
      resetValidation() {
        this.forms.forEach((form) => form.v?.$reset());
      },
    },
    getters: {
      formRequestOptions: (state) => {
        return state.forms.reduce((accumulator, currentValue) => {
          return Object.assign(
            accumulator,
            currentValue.requestOptions?.() ?? {},
          );
        }, {});
      },
      isFormsHasAnyError: (state) => {
        return state.forms.some((form) => state.isFormHasAnyError(form));
      },
      isFormHasAnyError: () => {
        return (form) => form.v?.$dirty && form.v?.$invalid;
      },
      isFormsReady: (state) => {
        return () => {
          state.forms.forEach((form) => form.v?.$validate());

          return !state.isFormsHasAnyError;
        };
      },
    },
  });
